import { useState } from "react";
import {
  Modal,
  TextStyle,
  Link,
  Spinner,
  Layout,
  Stack,
  DisplayText,
  Icon,
  FormLayout,
  Select,
  ChoiceList,
  TextField,
  Button,
} from "@shopify/polaris";
import Styles from "./onBoarding.module.scss";
import cn from "classnames";
import { TickSmallMinor, CancelSmallMinor } from "@shopify/polaris-icons";
import toastr from "react-hot-toast";
// import toastr from "../../lib/toastr";
import Cookies from "universal-cookie";
import api, { onboardingflowstage } from "../../api";
import main_apis from "api/main";

let options = [];
for (let i = 1; i <= 100; i++) {
  let value = i;
  // if (i >= 15)
  //     value = 30 + ((i - 14) * 10)
  // else if (i > 10)
  //     value = 10 + ((i - 10) * 5)
  options.push({ label: `${value}`, value: `${value}` });
}

const OnBoardingFlow = ({
  //timeGap = 14,
  messageEvent = "fulfillment",
  reviewStatus = reviewStatus,
  timegap = timegap ?? "14",
  change = change,
  onBoardingStep = onBoardingStep,
  importorders = importorders,
  migratedShop = migratedShop,
}) => {
  const [enabled, setEnabled] = useState(reviewStatus);
  const [showPopup, setShowPopup] = useState(true);
  const [step, setStep] = useState(onBoardingStep ? onBoardingStep + 1 : 1);
  const [timeGapVal, setTimeGapVal] = useState(timegap);
  const [messageEventVal, setMessageEventVal] = useState(messageEvent);
  const [days, setDays] = useState(["90"]);
  const [review, setReview] = useState("");
  const cookies = new Cookies();
  const [mount, setMount] = useState(false);
  const [spinner, setSpinner] = useState(false);

  // if(reviewStatus==true&&mount==false){
  //     if(timegap==10){
  //         setStep(2)
  //     }
  //     else
  //     {
  //         setStep(3)
  //     }

  //     setMount(true)
  //     console.log("reviewStatus not set")
  // }

  const handleReviewStatus = (value) => {
    setSpinner(true);
    // setReviewStatus(value)
    let flag = value;
    value = value == true ? false : true;
    //console.log(value)
    main_apis.manage_review_status
      (
        value,
        cookies.get("merchantToken"),
        cookies.get("shopId")
      )
      .then((returndata) => {
        //console.log(returndata.data);

        // if (
        //   returndata.data.confirmation_url &&
        //   returndata.data.confirmation_url != ""
        // ) {
        //   window.location.href = returndata.data.confirmation_url;
        // }
        toastr.success(res.data.Message);
        if (flag == true) {
          setEnabled(false);
        }
        setSpinner(false);
      });
    setSpinner(false);
  };
  //Message Timegap change action
  const handleTimeGapChange = (value) => {
    api.updateTimeGap(value).then((returndata) => {
      //console.log(returndata.data);
      toastr.success(returndata.data.Message);
    });
    setTimeGapVal(value);
  };
  //Message Schedule Event Change Action
  const handleMessageEventChange = (value) => {
    api.updateMessageEvent(value).then((returndata) => {
      //console.log(returndata.data);
      toastr.success(returndata.data.Message);
    });
    setMessageEventVal(value);
  };

  const handleNext = () => {
    if (step < 4) {
      if (step == 3) {
        importorders(days);
      }
      setStep(step + 1);
      change(step);
    } else {
      //console.log("Save Data")
      toggleShowPopup();
    }
    if (step == 4) {
      change(step + 1);
    }
  };
  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const toggleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const handleSave = () => {
    alert("Save");
  };
  const handeSubmit = () => {
    window.location.href = "https://apps.shopify.com/whatsapp-reviews";
  };

  const handleDoItLater = () => {
    //console.log("cookie skipped")
    cookies.set("onBoardingFlow", "skip", {
      path: "/",
      expires: new Date(Date.now() + 2592000),
    });
    toggleShowPopup();
  };

  //Message Timegap change action

  return (
    <Modal
      sectioned={false}
      open={showPopup}
      onClose={handleDoItLater}
      title=""
      hiddenTitle

      // primaryAction={{
      //     content: 'Save Date',
      //     onAction: handleSave,
      // }}
      // secondaryActions={[
      //     {
      //         content: 'Cancel',
      //         onAction: toggleShowPopup,
      //     },
      // ]}
    >
      <div
        style={{
          padding: "2rem",
          minHeight: "65vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <DisplayText>
            <TextStyle variation="strong">Let's get started</TextStyle>
          </DisplayText>
          <TextStyle variation="strong">
            Collect your customer review with Reviewbit with few steps
          </TextStyle>
          <div className={cn("spacer", Styles.onboardStepsHead)}>
            <Stack alignment="center">
              {[1, 2, 3, 4].map((item) => {
                let classes = cn(Styles.step);
                if (step > item) classes = cn(classes, Styles.completed);
                else if (step === item) classes = cn(classes, Styles.active);
                return item == 1 ? (
                  <span
                    className={enabled ? classes : cn(classes, Styles.pending)}
                  >
                    {item >= step ? (
                      item
                    ) : (
                      <Icon
                        source={enabled ? TickSmallMinor : CancelSmallMinor}
                      />
                    )}
                  </span>
                ) : (
                  <span className={classes}>
                    {item >= step ? item : <Icon source={TickSmallMinor} />}
                  </span>
                );
              })}
              {/* <span className={cn(Styles.step, Styles.active)}>2</span>
                        <span className={Styles.step}>3</span>
                        <span className={Styles.step}>4</span> */}
            </Stack>
            <div className={Styles.stepCount}>Step{step}</div>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          {/* Step1 */}
          <div className={cn(Styles.stepBody, step == 1 ? Styles.show : "")}>
            <Stack distribution="fill" alignment="center">
              <Stack.Item>
                <TextStyle variation="strong">
                  Enable or disable Whatsapp Review Request on your store
                </TextStyle>
                <br />
                <TextStyle variation="subdued">
                  You can disable it later from your whatsapp configuration
                  menu.
                </TextStyle>
              </Stack.Item>
              <Stack distribution="trailing">
                <button
                  className={cn(
                    Styles.enableButton,
                    enabled ? Styles.enabled : ""
                  )}
                  onClick={() => {
                    handleReviewStatus(), setSpinner(true);
                  }}
                  disabled={enabled}
                >
                  {spinner ? (
                    <Spinner
                      accessibilityLabel="Small spinner example"
                      size="small"
                    />
                  ) : enabled ? (
                    "Disable"
                  ) : (
                    "Enable"
                  )}
                </button>
              </Stack>
            </Stack>
          </div>

          {/* Step 2 */}
          <div className={cn(Styles.stepBody, step == 2 ? Styles.show : "")}>
            <Stack vertical>
              <TextStyle variation="strong">
                Schedule your review request
              </TextStyle>
              <TextStyle variation="subdued">
                Schedule your whatsapp review request anytime, like - after
                purchase, fullfilment or delivery.
              </TextStyle>
              <FormLayout>
                <div className={Styles.schedule_card_section}>
                  <div className={Styles.left_aligned_grid}>
                    <div style={{ textAlign: "center" }}>
                      <TextStyle>In</TextStyle>
                    </div>
                    <Select
                      labelInline
                      options={options}
                      onChange={handleTimeGapChange}
                      value={timeGapVal}
                    />
                    <div style={{ textAlign: "center" }}>
                      <TextStyle>Days</TextStyle>
                    </div>
                  </div>
                  <div className={Styles.right_aligned_grid}>
                    <div style={{ textAlign: "center" }}>
                      <TextStyle>After</TextStyle>
                    </div>

                    <Select
                      label=""
                      options={[
                        { label: "Purchase", value: "purchase" },
                        { label: "Fulfillment", value: "fulfillment" },
                        { label: "Delivery", value: "delivery" },
                      ]}
                      onChange={handleMessageEventChange}
                      value={messageEventVal}
                    />
                  </div>
                </div>
              </FormLayout>
            </Stack>
          </div>

          {/* Step 3 */}

          <div className={cn(Styles.stepBody, step == 3 ? Styles.show : "")}>
            <Stack vertical>
              <TextStyle variation="strong">
                Send Review request to past orders
              </TextStyle>
              <TextStyle variation="subdued">
                WhatsApp Review request will be sent in 24 hours, so you have
                enough time to customize the Whatsapp Review request and offer a
                discount code, if you wish
              </TextStyle>
              <FormLayout>
                <ChoiceList
                  choices={[
                    { label: "90 days back (Recommended)", value: "90" },
                    { label: "60 days back", value: "60" },
                    { label: "30 days back", value: "30" },
                    { label: "15 days back", value: "15" },
                    { label: "Skip", value: 0 },
                  ]}
                  selected={days}
                  onChange={(value) => {
                    console.log(value, days);
                    setDays(value);
                  }}
                  disabled={migratedShop}
                />
              </FormLayout>
            </Stack>
          </div>

          {/* Step 4 */}

          <div className={cn(Styles.stepBody, step == 4 ? Styles.show : "")}>
            <Stack vertical distribution="leading">
              {/* <TextStyle variation="strong">Write a review</TextStyle> */}
              <Stack distribution="">
                <Link
                  removeUnderline
                  url="https://apps.shopify.com/give-me-a-feedback#modal-show=ReviewListingModal"
                  external
                >
                  <Button primary>Write a review</Button>
                </Link>
              </Stack>
              {/* <TextStyle variation="subdued">
                            https://apps.shopify.com/whatsapp-reviews
                        </TextStyle> */}
              {/* <FormLayout> */}
              {/* <TextField
                                    multiline={4}
                                    placeholder="Start typing..."
                                    onChange={(value) => setReview(value)}
                                    value={review}
                                /> */}

              {/* </FormLayout> */}
            </Stack>
          </div>
        </div>

        <div className={Styles.footer}>
          <Stack distribution="fill" alignment="center">
            <TextStyle variation="strong">
              {step - 1}/4 step completed
            </TextStyle>
            <Stack distribution="trailing" alignment="center">
              <button className={Styles.normalButton} onClick={handleDoItLater}>
                Do It Later
              </button>
              <button className={Styles.actionButton} onClick={handleBack}>
                Back
              </button>
              <button className={Styles.actionButton} onClick={handleNext}>
                {step === 4 ? "Finish" : "Next"}
              </button>
            </Stack>
          </Stack>
        </div>
      </div>
    </Modal>
  );
};
export default OnBoardingFlow;
